body {
  margin: 0;
  /* font-family: 'Plus Jakarta Sans', sans-serif; */
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F0EDE4;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.react-code-input input {
  width: 90px !important;
  height: 90px !important;
  box-shadow: none !important;
  border: 1px solid #BBBBBB !important;
  color: #00000066 !important;
  border-radius: 0px !important;
}

@media (max-width: 1440px) {

  .react-code-input input {
    width: 70px !important;
    height: 70px !important;

  }
}

@media (max-width: 899.98px) {
  .react-code-input input {
    width: 42px !important;
    height: 42px !important;

  }
}

@media (max-width: 599.98px) {
  .react-code-input input {
    width: 40px !important;
    height: 40px !important;
    font-size: 12px !important;

  }
}